import React from "react";
import HelpPage from "../../components/helpPage";
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import { graphql } from "gatsby";
import Img from "gatsby-image";

export const query = graphql`
  query {
    zdjecie1: file(
      relativePath: {
        eq: "Prosty-system-CRM-dynamiczny-pulpit-tworzenie-widgetu.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie2: file(
      relativePath: {
        eq: "Prosty-system-CRM-dynamiczny-pulpit-dodawanie-kalendarza.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie3: file(
      relativePath: {
        eq: "Prosty-system-CRM-dynamiczny-pulpit-dodawanie-kodu-html.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie4: file(
      relativePath: {
        eq: "Prosty-system-CRM-dynamiczny-pulpit-wlasny-kod-html.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie5: file(
      relativePath: {
        eq: "Prosty-system-CRM-dynamiczny-pulpit-dodawanie-wlasnego-kodu-html.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie6: file(
      relativePath: {
        eq: "Prosty-system-CRM-dynamiczny-pulpit-przenoszenie-widgetow.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie7: file(
      relativePath: { eq: "Prosty-system-CRM-tworzenie-zakladek.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie8: file(
      relativePath: {
        eq: "Prosty-system-CRM-dynamiczny-pulpit-tworzenie-zakladek.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie9: file(
      relativePath: {
        eq: "Prosty-system-CRM-dynamiczny-pulpit-nowa-zakladka.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie10: file(
      relativePath: {
        eq: "Prosty-system-CRM-tworzenie-pulpitu-dla-wielu-pracownikow.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const DynamicznyPulpit = ({ data }) => {
  return (
    <HelpPage
      name="Dynamiczny pulpit"
      metaTitle="Pomoc - dynamiczny pulpit w prostym systemie CRM - QuickCRM"
      metaDescription="Zadania i zdarzenia w systemie CRM - pomoc • Tworzenie i wyświetlanie zadań wybranych pracowników, wyszukiwanie zdarzeń, podgląd • Łatwa koordynacja i planowanie pracy zespołu • Wypróbuj QuickCRM i sprzedawaj więcej, pierwsze 30 dni bezpłatnie!"
    >
      <div>
        <p>
          Dynamiczny pulpit widoczny jest po zalogowaniu. To miejsce, które
          możesz dopasować dowolnie do swoich potrzeb. To Ty decydujesz, jakie
          elementy chcesz wyświetlić. Biblioteka widgetów pozwala na dodawanie
          następujących elementów:
        </p>
        <p>
          <ol>
            <li>
              <strong>Raporty SQL</strong> – jeżeli często analizujesz dane,
              korzystając z wykresów możesz wyświetlić raporty do szans
              sprzedaży już w tym miejscu i oszczędzić czas na codzienne
              przechodzenie pomiędzy zakładkami.
            </li>
            <li>
              <strong>Kod HTML</strong> – ten widget daje Ci ogromne możliwości.
              Możesz wyświetlić na nim treść, grafikę, film czy skróty do
              konkretnych akcji w systemie, np. tworzenia kontrahenta.
            </li>
            <li>
              <strong>Kalendarz</strong> – jeżeli chcesz, możesz w tym miejscu
              wyświetlić również swój kalendarz i mieć szybki dostęp do
              zaplanowanych aktywności.
            </li>
            <li>
              <strong>Zadania i zdarzenia</strong> – jeśli kalendarz to dla
              Ciebie za mało i chcesz wyświetlić np. zaplanowane zadania
              konkretnego typu np. zaplanowane spotkania na ten tydzień, to
              skorzystaj z tego widgetu. W ten sposób zawsze widzisz aktualne,
              planowane spotkania w tym tygodniu.
            </li>
          </ol>
        </p>
      </div>
      <Tabs defaultTab="zakladka1">
        <TabList>
          <Tab tabFor="zakladka1">[1] Tworzenie widgetu</Tab>
          <Tab tabFor="zakladka2">[2] Tworzenie zakładki</Tab>
          <Tab tabFor="zakladka3">[3] Domyślny pulpit</Tab>
        </TabList>
        <TabPanel tabId="zakladka1">
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Dynamiczny pulpit tworzenie widgetu"
            fluid={data.zdjecie1.childImageSharp.fluid}
          />
          <p>[1] Aby dodać elementy do pulpitu, otwórz bibliotekę widgetów.</p>
          <p>[2] Możesz wybrać z biblioteki dowolny element.</p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Dynamiczny pulpit dodawanie kalendarza"
            fluid={data.zdjecie2.childImageSharp.fluid}
          />
          <br />

          <p>
            [3] Wybrany przez Ciebie widget automatycznie pojawi się na
            pulpicie.
          </p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Dynamiczny pulpit dodawanie kodu html"
            fluid={data.zdjecie3.childImageSharp.fluid}
          />
          <p>
            [4] Aby dodać kolejny element, ponownie otwórz bibliotekę widgetów.
          </p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Dynamiczny pulpit własny kod html"
            fluid={data.zdjecie4.childImageSharp.fluid}
          />

          <p>
            [5] Dodając kod HTML, pamiętaj o jego odpowiednim skonfigurowaniu.
            Kliknij ikonę koła zębatego, aby przejść do ustawień widgetu.
          </p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Dynamiczny pulpit dodawanie własnego kodu html"
            fluid={data.zdjecie5.childImageSharp.fluid}
          />
          <p>[6] Dodaj dowolną nazwę.</p>
          <p>[7] Ustal wysokość i szerokość widgetu.</p>
          <p>
            [8] Wstaw kod HTML umożliwiający wywołanie wybranego przez Ciebie
            działania.
          </p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Dynamiczny pulpit przenoszenie widgetów"
            fluid={data.zdjecie6.childImageSharp.fluid}
          />
          <p>
            [9] Wszystkie dodane na pulpit widgety możesz dowolnie przenosić i
            ustalić w ten sposób wygląd swojego pulpitu.
          </p>
        </TabPanel>
        <TabPanel tabId="zakladka2">
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Dynamiczny pulpit tworzenie zakładek"
            fluid={data.zdjecie7.childImageSharp.fluid}
          />
          <p>
            [1] Aby przejść do edycji zakładek pulpitu, kliknij w ikonę koła
            zębatego znajdującą się w prawym górnym rogu.
          </p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Dynamiczny pulpit CRM tworzenie zakładek"
            fluid={data.zdjecie8.childImageSharp.fluid}
          />
          <p>[2] Przypisz zakładce dowolną nazwę.</p>
          <p>[3] Wybierz liczbę kolumn, które znajdą się na pulpicie.</p>
          <p>[4] Dostosuj wymiary kolumn.</p>
          <p>[5] Pamiętaj, aby zapisać wprowadzone zmiany.</p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Dynamiczny pulpit nowa zakładka"
            fluid={data.zdjecie9.childImageSharp.fluid}
          />
          <p>Twoje ustawienia będą natychmiast widoczne na pulpicie.</p>
        </TabPanel>
        <TabPanel tabId="zakladka3">
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Dynamiczny pulpit dla wielu pracowników"
            fluid={data.zdjecie10.childImageSharp.fluid}
          />
          <p>
            [1] Możesz raz przygotować wygląd pulpitu i wykorzystać go dla całej
            grupy pracowników. Po przygotowaniu wyglądu pulpitu wystarczy
            kliknąć “utwórz z aktualnego”.
          </p>

          <p>
            [2] Przycisk “wgraj ustawienia” powoduje przypisanie określonego
            wyglądu pulpitu wybranym pracownikom.
          </p>
        </TabPanel>
      </Tabs>
    </HelpPage>
  );
};

export default DynamicznyPulpit;
